
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

@import "public/bower_components/font-awesome/scss/font-awesome.scss";

// Variables
@import "variables";

// Variables
@import "audi-fonts";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


* {
  border-radius: 0 !important;
}

body {
    background-color:white;
}

html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 60px;
}

@media (min-width: 768px){
    .container{
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar-static-top{
    background-color: black;
}
.navbar-brand{
    background-color: white;
    background-color: transparent;
    padding: 6px 15px 14px;
    > img {
        display:inline-block;
        position: relative;
        top: 0;
        margin-right: 15px;
        top: 3px !important;
        max-width: 230px !important;
        width: 230px !important;
    }
    &:hover{
        background-color: transparent !important;
    }
}
.navbar-default {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 30px;
}
.navbar-default .navbar-brand:hover, 
.navbar-default .navbar-brand:focus {
    background-color: transparent;
}
.navbar-default .navbar-nav > li > a,
.navbar-default .navbar-nav > li > a:hover, 
.navbar-default .navbar-nav > li > a:focus {
    font-family: 'Audi Legacy';
    font-size: 18px;
    color: white;
}
.navbar-default .navbar-brand{
  text-transform: uppercase;
  font-family: "roboto";
  color: black;
  font-weight: 800;
  font-size: 13px;
}
.navbar-default .navbar-toggle:hover, 
.navbar-default .navbar-toggle:focus {
    background-color: white;
}
.navbar-default .navbar-brand .subtitle{
  color: black;
  text-align: right;
  font-size: 10px;
  position: relative;
  top: -9px;
  font-style: italic;
  font-weight: 100;
}
@media (max-width: 405px){
    .navbar-default {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .navbar-collapse {
        overflow-x: visible;
        padding-right: 15px;
        padding-left: 15px;
        border-top: 0px solid transparent;
    }
}

#app-submenu{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    
    .container .row{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
    }
    
    ul{
        display: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        z-index: 1;
        background-color: white;
        
        @media (min-width: 768px){
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: baseline;
        }

        li{
            padding-left: 0;
            padding-bottom: 10px;
            padding-top: 10px;
            a{
                font-family: 'Audi Legacy';
                color: black;
                font-size: 17px;
                border-bottom: 1px solid black;
                padding-bottom: 7px;
                display: block;
                &:hover{
                    color: $brand-red;
                }
            }
            @media (min-width: 768px){
                padding-left: 30px;
                padding-bottom: 0;
                padding-top: 0;
                display: inline-block;
                a{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }
    }
}

#submenu-dropdown{
    right: 0;
    position: absolute;
    font-size: 32px;
    color: black;
    cursor: pointer;
    &:hover{
        color: $brand-red;
    }
    @media (min-width: 768px){
        display: none;
    }
}

#cookies-advice{
    display: none;
    background-color: #f5f5f5;

    .container .row{
        padding-top: 12px;
        padding-bottom: 12px;

        @media (max-width: 991px){
            .col-md-2{
                text-align: center;
                padding: 12px 0 0px;
            }
        }
        @media (min-width: 1025px){
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; 
            align-items: flex-start;
        }
        a{
            color: $brand-red;
            &:hover{
                color: black;
            }
        }
        .btn-accept{
            background-color: $brand-red;
            color: white;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            padding: 10px;
            margin: 0 0 12px;
            display: inline-block;
            &:hover, &:focus{
                color: white;
                background-color: $brand-red;
            }
        }
        .fa-remove{
            font-size: 18px;
            margin-left: 5px;
        }
    }
}

.extra-top-padding{
    padding-top: 40px;
}
.capitalize{
    text-transform: capitalize;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px; 
    background-color: #f5f5f5;
    @media (min-width: 320px) and (max-width: 767px){
        position: relative;
    }
    @media (max-width: 363px){
        position: absolute;
        line-height: 28px;
        padding-top: 20px;
    }

    .footer-brand,
    .footer-lopd{
        text-align: center;
    }
    @media (min-width: 768px){
        .footer-brand{
            text-align: left;
        }
        .footer-lopd{
            text-align: right;
        }
    }
}

body > .container {
    padding: 60px 15px 0;
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
    background-color: #f5f5f5;
}
.footer a{
    color: #777777;

}
a{
    text-decoration: none;
    outline: 0;
    color: black;
    &:hover, &.hovered{
        text-decoration: none;
        outline: 0;
        color: $brand-red;
    }
    &:focus{
        text-decoration: none;
        outline: 0;
        color: black;
    }
}

a.admin-action,
a.download-link
{
    color: $brand-red;
    &:hover, &:focus{
        text-decoration: none;
        outline: 0;
        color: black;
    }
}

.register-box .panel-body{
    padding-bottom: 187px;
}

.admin-action-block{
    padding-bottom: 15px;
}

.alert-success{
    font-weight: 600;
}

#search-pieces-form{
    display: inline-block;
}
.search-container {
    display: inline-block;
    input[type=text] {
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;;
    }
    button {
        float: right;
        padding: 6px 10px 5px;
        margin-right: 16px;
        background: #ddd;
        font-size: 17px;
        border: none;
        cursor: pointer;
        border: 1px solid #ccc;
        &:hover {
            background: #ccc;
        }
    }

    @media screen and (max-width: 600px) {
        button {
            float: none;
            display: block;
            text-align: left;
            width: 100%;
            margin: 0;
            padding: 14px;
        }
        input[type=text] {
            border: 1px solid #ccc;  
        }
    }
}

#carousel-home, #splash-home{
    padding: 0;
    max-height: 1010px;
    overflow: hidden;
    img{
        width: 100%;
    }
}
#carousel-home,{
    padding: 65px ;
    background-color: white;
}
#carousel-example-generic .carousel-caption{
    font-size: 49px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    background-color: rgba(0,0,0,0.4);;
    bottom: 35%;
    left: -60px;
    -webkit-transform: skew(20deg);
    -moz-transform: skew(-20deg);
    padding-left: 137px;
    padding-right: 95px;
    right: initial;
    text-shadow: none;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    padding-bottom: 20px;
    padding-top: 20px;
    a{
        color: white;
    }
    @media (max-width: 768px){
        position: absolute;
        font-size: 28px;
        left: 0;
        padding-left: 0;
        padding-right: 0;
        -webkit-transform: skew(20deg);
        -moz-transform: skew(0deg);
        bottom: 0;
        text-align: center;
        width: 100%;
    }
}
#carousel-example-generic .carousel-control{
    opacity: 0;
}
#carousel-example-generic .carousel-inner{
    overflow: hidden;
}
#carousel-example-generic .carousel-control-next,
#carousel-example-generic .carousel-control-prev{
    position: absolute;
    top: 40%;
    @media (max-width: 991px){
        display: none;
    }
}
#carousel-example-generic .carousel-control-next{
    right: 0;
    background-image: url('../img/right-arrow.svg');
    background-position: left center;
    background-repeat: no-repeat;
    margin-right: 0;
    width: 50px;
    height: 72px;
}
#carousel-example-generic .carousel-control-prev{
    left: 0;
    background-image: url('../img/left-arrow.svg');
    background-position: right center;
    background-repeat: no-repeat;
    margin-left: 0;
    width: 50px;
    height: 72px;
}

#custom-home-cars{
    padding-top: 65px;
    padding-bottom: 50px;
    font-family: 'Audi Legacy';
    ul{
        list-style-type: none;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;

        li{
            text-shadow: none;
            letter-spacing: 0.03em;
            text-align: center;
            margin-bottom: 50px;
            max-width: 100%;

            .img-container{
                background-color: white;
            }

            a{
                color: white;
                font-size: 22px;
            }
            p{
                padding-top: 25px;
                color: #e30613;
            }

            ul{
                &.cars-list{
                    display: none;
                }
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: baseline;
                li{
                    width: 100%;
                    padding: 5px 0;
                    margin-bottom: inherit;
                    a{
                        font-size: 13px;
                        @media (min-width: 768px){
                            font-size: 15px;
                        }
                        @media (min-width: 992px){
                            font-size: 11px;
                        }
                        @media (min-width: 1200px){
                            font-size: 13px;
                        }
                    }
                }
            }

            @media (min-width: 768px){
                max-width: 353px;
                img{
                    max-width: 353px;
                }
                a{
                    font-size: 25px;
                }
            }
            @media (min-width: 992px){
                max-width: 225px;
                img{
                    max-width: 225px;
                }
                a{
                    font-size: 19px;
                    line-height: 33px;
                }
            }
            @media (min-width: 1200px){
                max-width: 275px;
                img{
                    max-width: 275px;
                }
                a{
                    font-size: 22px;
                    line-height: 36px;
                }
            }
        }
        /*li:last-child a::after
        {
            content: '';
        }*/
    }
}


.hotspot-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom:20px;
    padding-left:0px;
    padding-right:0px;
    img {
        -webkit-transition: all 0.7s ease;
        transition: all 0.7s ease;
        width: 100%;
    }
}

.hotspot-wrapper:hover img {
    -webkit-transform:scale(1.3);
    transform:scale(1.3);
}

.hotspot-text {
    height: 192px;
    position: absolute;
    bottom: -50px;
    right:0px;
    left:0px;
    transition: all .2s linear;
    background: rgba(0,0,0,.7);
    color: white;
    font-size: 42px;
    padding: 0px 40px;
    font-family: Verdana, Helvetica, sans-serif;
    font-style: italic;
}

.hotspot-wrapper:hover .hotspot-text {
    bottom: 0;
    left:0;
    right:0;
}

ol.breadcrumb{
    background-color: transparent;
    padding: 8px 5px 20px;
    text-transform: capitalize;
    font-size: 13px;
    border-bottom: 1px solid $brand-grey;
    
    a, li{
        color: #999;
    }
    span{
        color: $brand-red;
    }

    > li + li::before {
        content: '>';
    }

    &.bottom{
        border-top: 1px solid $brand-grey;
        border-bottom: 0;
        padding: 20px 5px 8px;
        margin-top: 30px;
    }
}

.title-section-1{
    text-transform: uppercase;
    color: black;
    border-bottom: 1px solid $brand-red;
    h1{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.62em;
        letter-spacing: 1px;
    }
}

.title-section-2{
    text-transform: uppercase;
    color: black;
    h1{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.62em;
        letter-spacing: 1px;
    }
}

.title-section-3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    color: black;
    background-color: transparent;
    padding: 8px 5px;
    font-size: 13px;
    border-bottom: 1px solid #ccc;
    h1{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.62em;
        letter-spacing: 1px;
    }
    a{
        text-decoration: none;
        outline: 0;
        color: black;
        &:hover{
            text-decoration: none;
            outline: 0;
            color: $brand-red;
        }
        &:focus{
            text-decoration: none;
            outline: 0;
            color: black;
        }
    }
    &.w-link{
        > div{
            width: 50%;
        }
        h1{
            display: inline-block;
            padding-right: 10px;
        }
    }
}

.title-section-4{
    color: black;
    h2{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.62em;
        letter-spacing: 1px;
    }
}

.title-section-5{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    color: black;
    background-color: transparent;
    padding: 8px 5px;
    font-size: 13px;
    h1{
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.62em;
        letter-spacing: 1px;
    }
    a{
        text-decoration: none;
        outline: 0;
        color: black;
        &:hover{
            text-decoration: none;
            outline: 0;
            color: $brand-red;
        }
        &:focus{
            text-decoration: none;
            outline: 0;
            color: black;
        }
    }
    &.w-link{
        > div{
            width: 50%;
        }
        h1{
            display: inline-block;
            padding-right: 10px;
        }
    }
}

.alert-last-references{
    .fa-info-circle{
        font-size: 30px;
        float: left;
        padding-right: 20px;
        padding-top: 7px;
    }
    a{
        color: #31708f;
        font-weight: 600;
        &:hover{
            color: black;
        }
    }
}

.loading-dialog{
    font-size: 17px;
    letter-spacing: 1px;
    display: none;
    padding: 15px 0px;
}

.vw-panel .panel-body{
    font-weight: 600;
}

.asterisc{
    font-family: sans-serif;
}

.bg-carbon{
    background: transparent url("../img/bg-carbon.png") repeat top left;
    background-attachment: fixed;
    background-size: 100px;
}

#page-content{
    color: black;
    padding: 25px 0;
    p{
        padding-bottom: 15px;
    }
}

#my-settings{
    margin: 45px 0;

    button{
        margin-top: 20px;
    }
}

#navigator{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    text-transform: lowercase;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 15px 3px;
    margin-bottom: 20px;
    font-size: 17px;
}

#catalogue{
    ul{
        list-style-type: none;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
    }
    li{
        width: 30%;
        text-align: center;
    }
    a{
        text-align: center;
    }
    .thumbnail{
        background-repeat: no-repeat;
        background-size: cover;
    }
    img{
        max-width: 200px;
        &.brand{
            max-width: 100%;
        }
    }

    &.brands{
        padding-top: 70px;
        ul{
            justify-content: center;
        }
    }

    &.cars{
        ul{
            display: flex;
            flex-wrap: wrap;
            padding-top: 40px;
            li{
                flex-grow: 1; 
                width: 25%;
            }
        }
    }
}

#catalogue2{
    ul{
        list-style-type: none;
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    li{
        width: 30%;
        position: relative;
        overflow: hidden;
        padding-bottom: 50px;
    }
    li img{
        width: 100%;
        max-width: 340px;
    }
    li .brand-car-logo{
        z-index: 1;
        position: relative;
        height: 108px;
    }
    li .brand-car-logo img{
        width: 81%;
        top: 35px;
        position: relative;
    }
    li img.brand{
        width: 81%;
        top: 44px;
        left: -40px;
    }
    .brand-car-container{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }
    .brand-car-container::after {
        background-color: white;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 48px;
        transform-origin: 0% 0%;
        transform: translateX(-1px) translateY(333px) rotate(-6deg);
    }
    .brand-car-container::before {
        background-color: white;
        content: "";
        position: absolute;
        top: -61px;
        left: -1px;
        width: 100%;
        height: 100px;
        transform-origin: 0% 0%;
        transform: translateX(-10px) translateY(111px) rotate(-6deg);
    }
    .brand-btns-container{
        position: absolute;
        bottom: -5px;
        right: 12px;
    }
    .btn-red{
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        padding: 5px 10px;
        margin: 12px 10px 12px 0;
        display: inline-block;
        background-color: transparent;
        border: 1px solid $brand-red;
        color: black;
        border-radius: 3px;
        &:hover{
            background-color: $brand-red;
            color: white;
        }
    }
    .btn-red-v2{
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        padding: 5px 10px;
        margin: 12px 10px 12px 0;
        display: inline-block;
        background-color: $brand-red;
        border: 1px solid $brand-red;
        color: white;
        border-radius: 3px;
        &:hover{
            background-color: $brand-red;
            color: black;
        }
    }
    .btn-yellow{
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        padding: 5px 10px;
        margin: 12px 0 12px;
        display: inline-block;
        background-color: transparent;
        border: 1px solid #ff8802;
        color: black;
        border-radius: 3px;
        &:hover{
            background-color: #ff8802;
            color: white;
        }
    }
    .btn-grey{
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        padding: 5px 10px;
        margin: 12px 10px 12px 0;
        display: inline-block;
        background-color: transparent;
        border: 1px solid #777;
        color: black;
        border-radius: 3px;
        &:hover{
            background-color: #777;
            color: white;
        }
    }

    &.cars-index{
        ul.flexStart{
            justify-content: flex-start;
            li{
                margin-right: 140px;
            }
        }

        li {
            margin-top: 40px;
            .brand-car-logo {
                height: 32px;
                p{
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }
            img{
                max-height: 214px;
            }
        }
        .brand-car-container::before{
            top: -80px;
            height: 40px;
        }
        .brand-car-container::after {
            top: -91px;
            height: 40px;
        }
    }
}

.general-btn{
    background-color: $brand-red;
    color: white;
    font-weight: 600;
    margin: 10px auto;
    padding: 10px 15px;
    font-size: 16px;
    text-transform: uppercase;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    &:hover{
        background-color: #c80442;
        color:white;
    }
}

.catalogue-btn{
    background-color: $brand-red;
    color: white;
    font-weight: 600;
    margin: 10px auto;
    padding: 5px 0px;
    font-size: 16px;
    text-transform: uppercase;
    max-width: 175px;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    &:hover{
        background-color: #c80442;
    }
}

.btn-brand{
    background-color: $brand-red;
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    margin: 12px 0 12px;
    display: inline-block;
    &:hover, &:focus{
        color: white;
        background-color: #c80442;
    }
}
.btn-send-seat{
    text-transform: initial;
}
.btn-yellow{
    background-color: #f3b100;
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    margin: 12px 0 12px;
    display: inline-block;
    &:hover, &:focus{
        color: white;
        background-color: #d49c00;
    }
}
.btn-gray{
    background-color: #9a9a9a;
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    margin: 12px 0 12px;
    display: inline-block;
    &:hover, &:focus{
        color: white;
        background-color: #7c7c7c;
    }
}
.btn-green{
    background-color: green;
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    margin: 12px 0 12px;
    display: inline-block;
    &:hover, &:focus{
        color: white;
        background-color: green;
    }
}
.btn-cart{
    background-image: url("../img/cart.png");
    background-position: 12px 11px;
    background-repeat: no-repeat;
    background-size: 18px;
    padding-left: 43px;
    margin-top: 0;
    margin-bottom: 0;
}
.btn-print{
    background-color: #ddd;
    color: #3c3c3c !important;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    margin: 12px 0 12px;
    display: inline-block;
    &:hover, &:focus{
        color: #3c3c3c !important;
    }
    i{
        margin-right: 8px;
        font-size: 17px;
    }
}

.orange-btn{
    background-color: #ff8802;
    &:hover{
        background-color:#fea036;
    }
}
.btn-xs{
    border-color: transparent;
    font-size: 13px;
}
.btn.copy-to-cart{
    background-color: $brand-red;
    color: white;
    &:hover, &:focus{
        color: white;
        background-color: #c80442;
    }
}
.btn.pdf{
    background-color: blue;
    color: white;
    &:hover, &:focus{
        color: white;
        background-color: blue;
    }
}
.btn.xls{
    background-color: rgb(10, 209, 37);
    color: white;
    &:hover, &:focus{
        color: white;
        background-color: rgb(10, 209, 37);
    }
}
.btn.tracking{
    background-color: tomato;
    color: white;
    &:hover, &:focus{
        color: white;
        background-color: tomato;
    }
}
.btn.edit{
    background-color: #3f9f05;
    color: white;
    &:hover, &:focus{
        color: white;
        background-color: #3f9f05;
    }
}

.paginator{
    width: 100%;
    text-align: center;
    .pagination > li > a, .pagination > li > span {
        color: $text-color;
    }
    .pagination > li > a:hover, 
    .pagination > li > a:focus, 
    .pagination > li > span:hover, 
    .pagination > li > span:focus {
        color: $text-color;
    }
    .pagination > .active > a, 
    .pagination > .active > a:hover, 
    .pagination > .active > a:focus, 
    .pagination > .active > span, 
    .pagination > .active > span:hover, 
    .pagination > .active > span:focus {
        color: $text-color;
        background-color: $brand-grey;
        border-color: $text-color;
    }
}

#copy-to-cart-alert{
    display: none;
    margin-top: 15px;
    .alert-btns{
        padding-top: 10px;
        .btn{
            font-weight: 600;
        }
    }
}

.marketplace{
    .panel{
        text-align:right;
    }
    .filters {
        ul{
            float: left;
            padding: 0px;
        }
        li {
          display: inline;
          list-style-type: none;
          padding-right: 20px;
        }
    }
}

.ad{
    height:250px;
    position: relative;
    .cat{
        position: absolute;
        background-color: $brand-red;
        right: 0px;
        padding: 3px 24px;
        color: white;
        z-index: 1;
        top: 25px;
    }
    .layer{
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .caption{
        bottom:0;
        position: absolute;
        color:white;
        font-style: italic;
        text-align: left;
    }
    &:hover{
        .layer{
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}

.adshow{
     .cat{
        position: absolute;
        background-color: $brand-red;
        left: 15px;
        padding: 3px 24px;
        color: white;
        z-index: 1;
        top: 25px;
    }
    img{
        width:100%;
    }
}

.downloads{
    table{
        .fa-file-pdf-o{
            color: $brand-red;
        }
        .fa-file-archive-o{
            color:#ff7f02;
        }
        .fa-file-word-o{
            color:#1d4fff;
        }
        .fa-file-image-o{
            color:#3f9f05;
        }
        .fa-file-excel-o{
            color:green;
        }
        i{
           padding-right: 10px;
        }
    }

    .sidebar li.active a{
        text-decoration: none;
        background-color: #eeeeee;
        color: $brand-red;
    }
}

.show-cart-btn-wrapper{
    text-align: right;
    margin-bottom: 35px;
}
.align-right{
    text-align: right;
}

.error-popup, .success-popup{
    display: none;
    p{
        font-weight: 600;
    }
}

#contact-form,
#quiz-form
{
    margin: 30px 0;
}
#quiz-form h3{
    font-weight: 600;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 25px;
}

/**
* Parts section / Car Sections > Subsections list
*/
.parts-section{
    ul{
        list-style-type: none;
        padding: 0;
        li{
            color: $brand-red;
            text-transform: uppercase;
            border-bottom: 1px solid $brand-grey;
            margin-bottom: 20px;
        }
        ul{
            li{
                padding-left: 6px;
                border-bottom: 0;
                color: black;
                font-size: 15px;
                padding: 6px;
                margin-bottom: inherit;
                a{
                    color: black;
                    display: block;
                }
                a::before {
                    content: '\2022';
                    margin-right: 0.5em;
                }
                &:hover {
                    background-color: $brand-red;
                    color: white;
                    a{
                        color:white;
                    }
                }
            }
        }
    }
    h3{
        text-transform: uppercase;
        font-size: 19px;
        color: $brand-red;
        margin: 0 0 5px 0;
        letter-spacing: 1px;
        padding-top: 33px;
        font-weight: 600;
        padding-bottom: 5px;
    }
}

/**
* Subsection / Pieces catalog
*/
.hotspots-container {
    position: relative;
    display: inline-block;
}
.hotspot {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    width: 30px;
    background-color: transparent;
    opacity: 0.7;
    border-radius: 20px !important;
}

#pieces-image-container{
    text-align: center;
}
#pieces-dialogs-container{
    display: none;
}

#piece-dialog-alert{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
#piece-dialog-alert.active:after, #piece-dialog-alert.active:before {
	right: 99.7%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
#piece-dialog-alert.active:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 17px;
	margin-top: -17px;
}
#piece-dialog-alert.active:before {
    border-color: rgba(230, 230, 230, 0);
	border-right-color: #e6e6e6;
	border-width: 18px;
	margin-top: -18px;
}

.piece-dialog{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    min-width: 500px;
    border: 1px solid #e6e6e6;

    h4{
        margin: 0;
        margin-bottom: 10px;
        padding-bottom: 0;
        font-weight: 700;
        font-size: 13px;
    }
    p{
        font-size: 13px;
    }
    p.head{
        font-weight: 700;
    }
    > div{
        background: white;
        padding: 10px 0;
        width: 145px;

        &.shadow{
            background: #e6e6e6;
        }
    }
    > div.piece-main-info{
        width: 100%;

        &.text-left p{
            padding-left: 15px;
        }
    }
    > div.shadow{
        background: #e6e6e6;
    }
}

#pieces-list table{
    font-family: $font-family-secondary;
    width: 100%;
    cursor: context-menu;
    color: #333;
    font-size: 14px;
    margin-bottom: 20px;

    tr td
    {
        text-align: center;
        vertical-align: top;
        padding: 15px;

        span{
            color: #333;
        }
        span.legend{
            color: #999;
            font-size: 14px;
        }
    }
    tr th
    {
        text-align: center;
        padding: 8px 0;
        border-right: 1px solid white;
        font-size: 15px;
        font-weight: 600;
    }
    tr:nth-child(even){
        background-color: white;
    }
    tr:nth-child(odd){
        background-color: #F8F8F8;
    }
    tr.in-order{
        background-color: #f9e5e3;
    }
    tr:not(.thead):hover{
        background-color: #e6e6e6;
    }
    .thead{
        color: white;
        background-color: $brand-red !important;
        text-transform: uppercase;
        font-weight: 600;
    }
    tr td.description{
        text-align: center;
    }
}

#subsection-downloads{
    padding: 20px 0;
    ul{
        padding-left: 15px;
        list-style-type: square;
    }
}

input[type=number][name=units]{
    width: 35px;
}

/**
* Orders
*/
.order-info-wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 25px 0;
}
.order-name{
    color: black;
    padding: 15px 0 0;
    h2{
        font-size: 18px;
        span{
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}
.order-info{
    color: black;
    padding: 35px 0px;
    width: 50%;
    font-family: $font-family-secondary;
    p span{
        text-transform: uppercase;
        font-weight: 600;
    }
}

#pieces-order table,
#my-orders table,
#my-lists table,
#my-carts table
{
    font-family: $font-family-secondary;
    width: 100%;
    cursor: context-menu;
    color: #333;
    font-size: 14px;
    margin-bottom: 20px;

    tr:not(:last-child){
        border-bottom: 1px solid #333;
    }

    tr td
    {
        text-align: center;
        vertical-align: top;
        padding: 15px;

        span{
            color: #333;
        }
    }
    tr th
    {
        text-align: center;
        padding: 8px 0;
        font-size: 15px;
        font-weight: 600;
    }
    
    .thead, .tfoot{
        color: black;
        text-transform: uppercase;
        font-weight: 600;
    }

    td.cell-actions{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        min-width: 210px;
        a{
          margin: 0 3px;  
        }
    }
}

.form-dark{
    background-color: #ededed;
    padding: 20px 30px;
    margin: 35px 0;

    label{
        text-transform: capitalize;
        color: black;
    }
    textarea, input[type=text]{
        background-color: #d7d7d7;
        color: black;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: black;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: black;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: black;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: black;
    }

    .on-row{
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        .quantity{
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: baseline;
        }
        input[type=text]{
            min-width: 385px;
        }
        input[type=number]{
            width: 35px;
            margin-left: 15px;
        }
        a.add-piece{
            background-color: #4b4b4b;
            color: white;
            text-transform: uppercase;
            font-weight: 600;
            padding: 5px 40px;
            &:hover{
                background-color: black;
            }
        }
    }
}

p.note{
    font-size: 15px;
}
p.note-top{
    font-size: 15px;
    margin-bottom: 20px;
}

.order-actions{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    
    p.list-name-value{
        margin: 0;
        font-weight: 600;
        font-size: 16px;
    }
    .form-group{
        margin-bottom: 0;
    }
    input[type=text]{
        min-width: 260px;
    }
    a{
        margin-left: 10px;
        padding: 6px 12px;
    }
}

.terms-conditions{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;


    input[type=checkbox]{
        margin-left: 5px;
        min-width: 10px;
    }
}

.set-address{
    font-family: $font-family-secondary;

    input[type=radio]{
        margin-right: 35px;
    }

    .current-address p{
        margin-bottom: 0;
        line-height: 23px;
    }
}

span.pre-order,
span.new-order
{
    font-weight: 600;
    color: $brand-red !important;
}
span.pending{
    font-weight: 600;
    color: #f06c14 !important;
}
span.cancelled{
    font-weight: 600;
    color: #4b4b4b !important;
}
span.processing{
    font-weight: 600;
    color: #2f2fe9 !important;
}
span.dispatched{
    font-weight: 600;
    color: green !important;
}

.tnt-tracking{
    input[name=tnt_tracking_number]{
        width: 25%;
        margin: 0 0 0 auto;
        background-color: #9a9a9a;
        border: 1px solid #9a9a9a;
        color: black;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: black;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: black;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: black;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: black;
    }
}

#filter-orders-form{
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: baseline;
}

#no-search-results p{
    text-align: center;
    padding: 25px 0;
    font-size: 18px;
    font-weight: 600;
}

/**
* Quiz
*/
.quiz-item-box {
    margin-bottom: 35px;
    &.last-quiz-item-box{
        margin-bottom: 0;
    }
}
.rating{
    ul{
        font-size: 20px;
        padding: 15px 0 0 15px;
        margin-bottom: 0;
    }
    li{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
    }
    p{
        display: inline-block;
        margin: 0;
        margin-right: 10px;
        font-size: 17px;
        font-weight: 600;
    }
    .rating-label{
        min-width: 285px;
    }
    .fa-icon, .starability-basic{
        display: inline-block;
    }
    .starability-basic{
        min-height: 35px;
        margin-right: 10px;
    }
    .far{
        font-size: 32px;
    }
    .fa-frown{
        color: $brand-red;
    }
    .fa-smile{
        color: #10c910;
    }
}
